@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300&display=swap');
.links-container {
  margin-top: 60px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 60px;
  font-family: 'Noto Sans', Verdana, sans-serif;
  height: 600px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
   /* For Safari */
  scroll-behavior: auto;
}


.logInButton{
  text-align: right;
}

.searchItem{
  backdrop-filter: blur(30px);
}
.showAllButton{
  float: left;
}

.submitButton{
  float: left;
}
.siteFooter{
  backdrop-filter: blur(30px);
  background-color: rgb(0,0,150,0.1);
  position:absolute;
   bottom:0;
   width:100%;
   height:15rem;
}

#skaoNavID{
  backdrop-filter: blur(30px);
  background-color: rgb(0,0,150,0.1);
}
.white-class{
  color: #ffffff !important;
}

.me-auto{
  font-family: 'Noto Sans', Verdana, sans-serif;  
  font-size:x-large;  
}

.navItem{
  color:aliceblue;
}

td{
  padding:5px;
}
body {
  /* Make background image cover */
  background-size: cover;
  background-image: url("SKA at Night_0.jpg");
  position: absolute;
  width: 100%;
  height: 100%;
  font-family: 'Noto Sans', Verdana, sans-serif;  
}


#skaoPDF{
  height:100%;
  width:100%;
  
}

.video-container {
  position: relative;
  width: 100%; /* Ensure the container takes the full width */
  height: 0; /* Use padding-top to control aspect ratio */
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  background-color: black; /* Fallback background */
}

.video-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle, rgba(0,0,0,0) 60%, rgba(0,0,0,0.75) 100%);
  pointer-events: none;
}

.video-container > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.pdfIframe{
  height:100vh;
  width:100vw;
}
html {
  position: relative;
  min-height: 100%;
}

.footer-padding{
  padding-bottom: 15rem;
}

#searchID{
  text-align: right;
}

.nav-padding{
  padding-bottom: 60px;
}

#SKAORequestAccess{
  color:white;
}
#SKAORequestAccessForm{
  margin:20px;
  margin-bottom:8rem;
  backdrop-filter: blur(20px);
  padding:20px;
  border-radius: 20px;
}

#SKAORequestAccessSubmit{
  margin-top: 15px;
}

.check-container{
  margin-bottom: 8px;
  margin-left:10px;
}

#SKAORequestAccessHeaderContainer{
  width:100%;
}
#SKAORequestAccessHeader{
  text-align: center;
  margin: 20px 0 20px 0;
  
}

.ripple-wave{
  display:none;
}

#skao-search-whole-container{
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  min-width:100vw;
}

#skao-search-container{
  display: inline-block;
  width:100% !important;
}
.skao-search-middle{
  height:75vh;
}

.no-styling{
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
  color: white !important;
}

.no-styling:hover{
  color: pink;
}


/* Class for making URLs pink and darker pink on hover */
.pink-url{
  color: pink;
}

.pink-url:hover{
  color: #ff00ff;
}

.document-table {
  position: relative;
  background-color: transparent !important;
}


.document-table::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: /* Your background here */;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px); /* For Safari browser support */
}

.document-table, .urlTableHeader, .urlTable, .documentRow, .document-table th, .document-table td {
  background-color: transparent !important; /* Ensures that the blurred background is visible */
}

.document-table {
  position: relative;
  background-color: transparent !important;
  table-layout: fixed; /* Add this line to ensure columns can have fixed width */
  width: 100%; /* Ensure the table spans the width of its container */
}

/* Ensure the cell with the scrollable content has a fixed width and allows for horizontal scrolling */
.document-table .copyright-holder-cell {
  width: 50px; /* Example fixed width */
  overflow: hidden;
}

.scrollable-cell {
  overflow-x: auto; /* Enables horizontal scrolling */
  max-width: 100%; /* The scrollable content will not exceed the cell's width */
  white-space: nowrap; /* Prevent text from wrapping to a new line */
}